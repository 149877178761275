import React from 'react'
import {
    ResultBlock,
    ResultCourt,
    ResultTitle,
    ResultsBody,
    ResultAmount
} from './ResultStyles'

const Result = ({name, courtName, resultAmount, body, cssClass}) => {

    return (
        <ResultBlock className={cssClass}>
            <ResultCourt>{courtName}</ResultCourt>
            <ResultTitle>{name}</ResultTitle>
            <ResultAmount>{resultAmount}</ResultAmount>
            <ResultsBody dangerouslySetInnerHTML={{ __html: body }} />
        </ResultBlock>
    )
}

export default Result
