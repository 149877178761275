import React from 'react'
import { Container, Eyebrow, PrimaryButton } from '../../styles'
import {
    HeroElement,
    HeroTitle,
    HeroControls,
    HeroContentWrapper,
    HeroScroll
} from './HeroStyles'


const transition = {
    duration: 0.25,
    ease: [0.43, 0.13, 0.23, 0.96]
}

const buttonContainer = {
    initial: {
        opacity: 0
    },
    animate: {
        opacity: 1,
        transition: {
            delayChildren: 0.5,
            staggerChildren: 0.12,
          },
    }
}

const button = {
    initial: {
        opacity: 0,
        y: 10
    },
    animate: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 1,
            ...transition
        },
    }
}

const Hero = () => {
    return (
        <HeroElement
            large
            role="banner"
        >
            <Container flex fullHeight column justifyCenter alignCenter>
                <HeroContentWrapper>
                    <Eyebrow
                        centered
                        large
                        initial={{opacity: 0, y: 20 }}
                        animate={{
                            opacity: 1,
                            y: 0,
                            transition: { delay: 0.35, ...transition}
                        }}
                    >
                        Personal Injury Law Firm
                    </Eyebrow>
                    <HeroTitle
                        initial={{ opacity: 0, y: 30, ...transition }}
                        animate={{
                            opacity: 1,
                            y: 0,
                            transition: { delay: 0.45, ...transition}
                        }}
                    >
                        Delivering Top Notch Results
                    </HeroTitle>
                    <HeroControls
                        variants={buttonContainer}
                        initial="initial"
                        animate="animate"
                    >
                        <PrimaryButton to="/results/" modifiers={['large', 'green']} variants={button}>
                            See Our Results
                        </PrimaryButton>
                        <PrimaryButton to="/contact-us/" modifiers={['large', 'greenSecondary']} variants={button}>
                            Free Case Review
                        </PrimaryButton>
                    </HeroControls>
                </HeroContentWrapper>
            </Container>
            <HeroScroll
                initial={{
                    opacity: 0,
                    y: 16,
                    x: "-50%"}}
                animate={{
                    opacity: 1,
                    y: 0,
                    x: "-50%",
                    transition: { delay: 0.9, ...transition}
                }}
            >
                Scroll
            </HeroScroll>
        </HeroElement>
    )
}

export default Hero
