import styled from 'styled-components'
import { defaultTheme } from '../../styles'

export const ResultsSliderElement = styled.div`
    background: ${defaultTheme.darkBlue};
    padding: 32px 0;
    overflow: hidden;
    width: 100vw;

    @media(min-width: 900px) {
        padding: 56px 32px;
    }

    .slick-track {
        display: flex;
       
    }

    .slick-slide {
        height: inherit;
        > div {
            height: 100%;
        }
    }

    .slick-slide:not(.slick-active) {
        opacity: 0.5;
    }
`;