import React from 'react'
import {
    PracticeAreaBlockElement,
    PracticeAreaThumbnail,
    PracticeAreaSmallTitle
} from './PracticeAreaBlockStyles'
import Image from 'gatsby-image'

const PracticeAreaBlock = ({title, practiceUrl, imageSrc, styles}) => {
    return (
        <>
            <PracticeAreaBlockElement to={practiceUrl} style={styles}>
                <PracticeAreaThumbnail>
                    <Image
                        fluid={imageSrc} 
                        alt={title}
                    />
                </PracticeAreaThumbnail>
                <div>
                    <PracticeAreaSmallTitle>
                        {title}
                    </PracticeAreaSmallTitle>
                </div>
            </PracticeAreaBlockElement>
        </>
    )
}

export default PracticeAreaBlock
