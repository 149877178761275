import styled, { css } from 'styled-components'
import { Container, PrimaryButton } from '../../styles'
import { motion } from "framer-motion"
import BackgroundImage from "../../images/la-at-night-bg.jpg"

export const HeroElement = styled(motion.section)`
    position: relative;
    height: 40vh;
    background:  #0D1520;
    color: #fff;

    ${props => props.large && css`
        height: 100vh;
        min-height: 500px;
        background-image: url(${BackgroundImage});
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        @media(min-width: 900px) {
            height: 100vh;
            min-height: 800px;
        }

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: rgb(13,21,32);
            background: linear-gradient(0deg, rgba(13,21,32,0) 0%, rgba(13,21,32,1) 90%);
            content: "";
        }

        &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: rgb(13,21,32);
            background: linear-gradient(180deg, rgba(13,21,32,0) 0%, rgba(13,21,32,1) 90%);
            content: "";
        }
    `};

    ${props => props.centeredCopy && css`
        text-align: center;

        h1 {
            margin: 0 auto;
            max-width: 100%;
        }
    `};

    ${Container} {
        position: relative;
        z-index: 2;
    }
`;

export const HeroTitle = styled(motion.div)`
    margin: 12px auto 0;
    max-width: 90%;
    font-size: 34px;
    font-weight: 700;
    letter-spacing: 0.031em;
    line-height: 1.25;

    @media(min-width: 360px) {
        font-size: 42px;
    }

    @media(min-width: 750px) {
        font-size: 72px;
    }

    @media(min-width: 900px) {
        margin-bottom: 72px;
        max-width: 600px;
    }
`;

export const HeroControls = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 48px;

    @media(min-width: 700px) {
        flex-direction: row;
        padding-top: 0;
    }
`;

export const HeroContentWrapper = styled.div`
    padding: 0;
    text-align: center;

    ${PrimaryButton} {
        margin: 0 12px 24px;

        @media(min-width: 700px) {
            margin-bottom: 0;
        }
    }
`;

export const HeroScroll = styled(motion.p)`
    position: absolute;
    bottom: 128px;
    left: 50%;
    margin: 0;
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    transform: translateX(-50%);
    z-index: 1;

    @media(min-width: 700px) {
        bottom: 48px;
    }
`;