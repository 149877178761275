import React from 'react'
import { Container, PrimaryButton } from '../../styles'
import {
    ImageTextBlockElement,
    ImageWrapper,
    TextWrapper
} from './ImageTextBlockStyle'
import Image from 'gatsby-image'

const ImageTextBlock = ({imageSrc, bodyCopy, buttonLabel, buttonUrl}) => {
    return (
        <Container>
            <ImageTextBlockElement>
                <ImageWrapper>
                    <Image
                        fluid={imageSrc} 
                        alt=""
                    />
                </ImageWrapper>
                <TextWrapper>
                    <div dangerouslySetInnerHTML={{__html: bodyCopy}} />
                    <PrimaryButton to={ buttonUrl } modifiers={['large', 'green']}>
                        { buttonLabel }
                    </PrimaryButton>
                </TextWrapper>
            </ImageTextBlockElement>
        </Container>
    )
}

export default ImageTextBlock
