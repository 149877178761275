import React from 'react'
import PracticeAreaBlock from '../PracticeAreaBlock'
import Slider from "react-slick"
import usePracticeAreas from '../../hooks/use-practiceareas'
import styled from 'styled-components'
import { Eyebrow, Container, defaultTheme } from '../../styles'

function Next(props) {
    const { className, onClick } = props;
    return (
        <button className={className} onClick={onClick} aria-label="Advance to next slide">
            <svg aria-hidden="true" data-prefix="far" data-icon="arrow-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="white" d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z"/>
            </svg>
        </button>
    );
  }
  
  function Prev(props) {
    const { className, onClick } = props;
    return (
        <button className={className} onClick={onClick} aria-label="Advance to previous slide">
            <svg aria-hidden="true" data-prefix="far" data-icon="arrow-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="white" d="M229.9 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L94.569 282H436c6.627 0 12-5.373 12-12v-28c0-6.627-5.373-12-12-12H94.569l155.13-155.13c4.686-4.686 4.686-12.284 0-16.971L229.9 38.101c-4.686-4.686-12.284-4.686-16.971 0L3.515 247.515c-4.686 4.686-4.686 12.284 0 16.971L212.929 473.9c4.686 4.686 12.284 4.686 16.971-.001z"/>
            </svg>
        </button>
    );
  }

const PracticeAreaSlider = () => {
    var settings = {
        centerMode: true,
        dots: true,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <Next />,
        prevArrow: <Prev />,
        appendDots: dots => (
            <div>
              <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
          ),
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                }
            },
            {
                arrows: false,
                breakpoint: 450,
                settings: {
                  slidesToShow: 1,
                  centerMode: false
                }
            },
        ]
    };

    const practiceAreas = usePracticeAreas();

    const practiceSlides = practiceAreas.slice(0, 6).map((practice) =>
        <PracticeAreaBlock
            practiceUrl={practice.slug}
            title={practice.title}
            key={practice.title}
            imageSrc={practice.featuredImage.sharp.fluid}
        />
    );
    return (
        <PracticeAreaSection>
            <Container flex column justifyCenter alignCenter>
                <Eyebrow centered small>Our Practice Areas</Eyebrow>
                <h2 className="blue">How We Can Help</h2>
            </Container>
            <PracticeAreaSliderElement>
                <Slider {...settings}>
                    {practiceSlides}
                </Slider>
            </PracticeAreaSliderElement>
        </PracticeAreaSection>
    )
}

export default PracticeAreaSlider

const PracticeAreaSection  = styled.div`
    position: relative;
    padding: 40px 0 80px;
    background: ${defaultTheme.lightBlue};
    
    @media(min-width: 900px) {
        padding: 80px 0 140px;
    }
`;

const PracticeAreaSliderElement = styled.div`
    margin-top: 24px;
    
    @media(min-width: 900px) {
        margin-top: 48px;
    }

    .slick-slider {
        position: static;
    }

    .slick-dots {
        bottom: 20px;

        @media(min-width: 900px) {
            bottom: 46px;
        }
        li {
            &.slick-active {
                button {
                    background: ${defaultTheme.darkBlue};
                }
            }

            button {
                height: 10px;
                padding: 0;
                width: 10px;
                border: 1px solid ${defaultTheme.darkBlue};
                border-radius: 50%;

                &:before {
                    content: none;
                }
            }
        }
    }

    .slick-slide:not(.slick-active) {
        opacity: 0.5;
    }

    .slick-prev,
    .slick-next {
        display: none;

        @media(min-width: 900px) {
            top: auto;
            bottom: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 40px;
            background: ${defaultTheme.darkBlue};
            border-radius: 50%;
            z-index: 5;

            svg {
                transform: scale(40%);
            }
        }
        
        &:before {
            content: none;
        }
    }

    .slick-prev {
        left: 10%;
    }

    .slick-next {
        right: 10%;
    }
`;
