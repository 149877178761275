import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/Layout'
import SEO from "../components/SEO"
import Hero from "../components/Hero"
import ResultsSlider from "../components/ResultsSlider"
import SectionHeading from "../components/SectionHeading"
import ImageTextBlock from "../components/ImageTextBlock"
import PracticeAreaSlider from "../components/PracticeAreaSlider"
import TestimonialsSlider from '../components/TestimonialsSlider'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: {absolutePath: {regex: "/images/"}}) {
        nodes {
          name
          id
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)

  const allImages = data.allFile.nodes; 

  return (
    <Layout>
      <SEO
        title="South Bay & West Hollywood Personal Injury Attorney | Craig Charles Law"
        metaDesc="At the Law Office of Craig Charles, we believe every victim of a serious injury is owed three things: Compassion, communication and compensation."
      />
      <Hero />
      <ResultsSlider />
      <SectionHeading
        color="blue"
        heading="Accidents are traumatic experiences. We understand. We will fight to help you recover."
      />
      <ImageTextBlock
        imageSrc={allImages.find(node => node.name === "craig-charles-lawyer").childImageSharp.fluid}
        bodyCopy="<p>We believe every victim of a serious injury is owed three things: Compassion, communication and compensation. An injury due to someone else’s negligence is often deeply traumatic. That’s why we treat every client with empathy, communicate promptly and fight zealously to ensure you’re fairly compensated.</p>"
        buttonUrl="/about/"
        buttonLabel="About Craig Charles Law"

      />
      <PracticeAreaSlider />
      <TestimonialsSlider />
    </Layout>
  )
}

export default IndexPage
