import styled from 'styled-components'
import {Link} from 'gatsby'

import { motion } from 'framer-motion'
import { defaultTheme } from '../../styles'

export const PracticeAreaBlockElement = styled(motion(Link))`
    display: block;
    margin: 0 16px;
`;

export const PracticeAreaThumbnail = styled.div`

`;

export const PracticeAreaSmallTitle = styled.h2`
    margin: 24px 0 0;
    padding-top: 16px;
    border-top: 1px solid ${defaultTheme.darkBlue};
    color: ${defaultTheme.darkBlue};
    font-size: 20px;
    font-weight: 500;
`;