
import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Result from '../Result'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import {
    ResultsSliderElement
} from './ResultsSliderStyles'

const ResultsSlider = props => {
    const data = useStaticQuery(graphql`
        {
            allResultsJson(limit: 6) {
                nodes {
                    title
                    courtHouse
                    amount
                    resultBody
                }
            }
        }
    `)

    var settings = {
        centerMode: true,
        arrows: false,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                  slidesToShow: 3,
                  dots: true
                }
            },
            {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                }
            },
            {
                breakpoint: 350,
                settings: {
                  slidesToShow: 1,
                  centerMode: false
                }
            },
        ]
    };

    return (        
        <ResultsSliderElement>
            <Slider {...settings}>
                {data.allResultsJson.nodes.map(resultSlide => (
                    <Result
                        key={resultSlide.title}
                        name={resultSlide.title}
                        courtName={resultSlide.courtHouse}
                        resultAmount={resultSlide.amount}
                        body={resultSlide.resultBody}
                    />
                ))}
            </Slider>
        </ResultsSliderElement>
    )
}

export default ResultsSlider
